import React, { useState } from 'react';
import trainingImage from '../images/training.jpg';
import judges_1 from '../images/judges/judges_1.jpg';
import judges_2 from '../images/judges/judges_2.jpg';
import judges_3 from '../images/judges/judges_3.jpg';
import judges_4 from '../images/judges/judges_4.jpg';
import judges_5 from '../images/judges/judges_5.jpg';
import judges_6 from '../images/judges/judges_6.jpg';
import judges_7 from '../images/judges/judges_7.jpg';
import judges_8 from '../images/judges/judges_8.jpg';
import judges_9 from '../images/judges/judges_9.webp';
import judges_10 from '../images/judges/judges_10.jpg';
import judges_11 from '../images/judges/judges_11.webp';
import judges_12 from '../images/judges/judges_12.jpg';
import judges_13 from '../images/judges/judges_13.jpg';
import judges_14 from '../images/judges/judges_14.webp';
import judges_15 from '../images/judges/judges_15.webp';
import judges_16 from '../images/judges/judges_16.webp';
import judges_17 from '../images/judges/judges_17.webp';
import judges_18 from '../images/judges/judges_18.webp';
import judges_19 from '../images/judges/judges_19.webp';
import judges_20 from '../images/judges/judges_20.webp';
import judges_21 from '../images/judges/judges_21.jpg';
import judges_22 from '../images/judges/judges_22.webp';
import judges_23 from '../images/judges/judges_23.jpg';
import judges_24 from '../images/judges/judges_24.jpg';
import judges_25 from '../images/judges/judges_25.jpg';
import judges_26 from '../images/judges/judges_26.jpg';
import judges_27 from '../images/judges/judges_27.jpg';
import judges_28 from '../images/judges/judges_28.jpg';
import judges_29 from '../images/judges/judges_29.jpg';
import judges_30 from '../images/judges/judges_30.jpg';

const Judges = () => {
  const [activeYear, setActiveYear] = useState(2019);

  const judgesData = {
    2019: [
        { name: 'Анна Петрова', country: 'Россия', description: 'Генеральный директор крупной сети ресторанов, обладатель множества отраслевых наград за развитие ресторанного бизнеса и участие в международных проектах.', image: judges_1 },
        { name: 'Олег Исмаилов', country: 'Казахстан', description: 'Руководитель крупной строительной компании, внес значительный вклад в развитие инфраструктуры страны.', image: judges_2 },
        { name: 'Наталья Бекмаева', country: 'Беларусь', description: 'Эксперт в области корпоративного управления, руководитель нескольких международных проектов в сфере устойчивого развития.', image: judges_3 },
        { name: 'Владимир Карпов', country: 'Россия', description: 'Владелец компании в области высоких технологий, один из ведущих экспертов по внедрению инноваций в промышленность.', image: judges_4 },
        { name: 'Светлана Николаева', country: 'Россия', description: 'Лауреат нескольких премий за вклад в развитие малого и среднего бизнеса, основательница обучающих программ для предпринимателей.', image: judges_5 },
      ],
    2020: [
      { name: 'Екатерина Соколова', country: 'Россия', description: 'Специалист по устойчивому развитию и корпоративной социальной ответственности, директор компании с международным опытом.', image: judges_6 },
      { name: 'Олег Баранов', country: 'Россия', description: 'Основатель и директор технологической компании, лауреат премий за инновационные разработки в области ИТ.', image: judges_7 },
      { name: 'Ахмед Алиев', country: 'Азербайджан', description: 'Руководитель группы компаний, эксперт по международному бизнесу и устойчивому развитию.', image: judges_8 },
      { name: 'Роман Ковалев', country: 'Россия', description: 'Президент Ассоциации малого бизнеса России, признанный эксперт в области предпринимательства и устойчивого развития.', image: judges_9 },
      { name: 'Жанна Мухтарова', country: 'Узбекистан', description: 'Эксперт по вопросам развития бизнеса в странах Центральной Азии, консультант по вопросам предпринимательства.', image: judges_10 },
    ],
    2021: [
      { name: 'Нурсултан Аманов', country: 'Казахстан', description: 'Руководитель фонда устойчивого развития, эксперт по корпоративной социальной ответственности в странах СНГ.', image: judges_11 },
      { name: 'Людмила Захарова', country: 'Россия', description: 'Основатель и директор успешного стартапа в области e-commerce, активно участвует в международных бизнес-форумах.', image: judges_12 },
      { name: 'Марина Бекетова', country: 'Украина', description: 'Эксперт в области развития малого и среднего бизнеса, участник международных проектов по поддержке предпринимателей.', image: judges_13 },
      { name: 'Игорь Соколов', country: 'Беларусь', description: 'Экономист, консультант по вопросам устойчивого развития и зеленой энергетики.', image: judges_14 },
      { name: 'Михаил Смирнов', country: 'Россия', description: 'Основатель сети успешных IT-компаний, лауреат множества национальных премий за внедрение инновационных технологий.', image: judges_15 },
    ],
    2022: [
      { name: 'Алексей Тимофеев', country: 'Россия', description: 'Лидер в сфере агротехнологий, консультант по внедрению устойчивых решений для сельскохозяйственных предприятий.', image: judges_16  },
      { name: 'Наталья Орлова', country: 'Россия', description: 'Руководитель одного из крупнейших инвестиционных фондов в России, активно поддерживающего проекты в области высоких технологий.', image: judges_17 },
      { name: 'Бакыт Ахметов', country: 'Казахстан', description: 'Эксперт по цифровой трансформации бизнеса, руководитель международных проектов в сфере инноваций.', image: judges_18 },
      { name: 'Ирина Волкова', country: 'Молдова', description: 'Эксперт по устойчивому развитию в сельском хозяйстве, лауреат нескольких национальных наград за экологические проекты.', image: judges_19 },
      { name: 'Магомед Алиев', country: 'Азербайджан', description: 'Руководитель крупных инфраструктурных проектов, специалист по развитию международного бизнеса.', image: judges_20 },
    ],
    2023: [
      { name: 'Анатолий Петров', country: 'Россия', description: 'Специалист по инновациям в промышленности, лауреат премий за внедрение новых технологий.', image: judges_21 },
      { name: 'Екатерина Фролова', country: 'Россия', description: 'Директор консалтинговой компании, признанный эксперт в области корпоративной социальной ответственности и устойчивого развития.', image: judges_22 },
      { name: 'Надежда Кузнецова', country: 'Беларусь', description: 'Эксперт по стратегическому управлению, участник международных проектов по развитию бизнеса в странах СНГ.', image: judges_23 },
      { name: 'Арсен Исаков', country: 'Казахстан', description: 'Инвестор, активный участник проектов по поддержке стартапов и инноваций в Центральной Азии.', image: judges_24 },
      { name: 'Лейла Мамедова', country: 'Азербайджан', description: 'Руководитель международного проекта по развитию устойчивого бизнеса и корпоративной социальной ответственности.', image: judges_25 },
    ],
    2024: [
      { name: 'Иван Королев', country: 'Россия', description: 'Эксперт в области цифровых технологий и автоматизации, лауреат множества премий за инновационные разработки в сфере ИТ и промышленности.', image: judges_26 },
      { name: 'Ольга Воронцова', country: 'Украина', description: 'Руководитель крупного консалтингового агентства, известный специалист по управлению бизнес-рисками и устойчивому развитию в странах СНГ.', image: judges_27 },
      { name: 'Юлия Байбабина', country: 'Казахстан', description: 'Предприниматель, владелец торговой марки Naomi Home Textile, владелец Патента на Изобретение “Сменный антибактериальный чехол с функцией самодезинфекции для медицинского постельного белья”, победитель Национальной премии Президента Республики Казахстан "Золотое качество",  Победитель Международной Премии "Предприниматель года СНГ"', image: judges_28 },
      { name: 'Алия Ахмедова', country: 'Азербайджан', description: 'Эксперт в области корпоративной социальной ответственности, консультант международных компаний по вопросам устойчивого бизнеса и внедрения социальных программ.', image: judges_29 },
      { name: 'Сергей Беляев', country: 'Беларусь', description: 'Специалист по инновациям в сфере производственных процессов, участник международных проектов по цифровой трансформации и повышению эффективности производственных систем.', image: judges_30 },
    ],
  };

  const renderJudges = (year) => {
    return judgesData[year].map((judge, index) => (
      <div className="judge-card" key={index}>
        <img src={judge.image} alt={judge.name} className="judge-photo" />
        <div className="judge-info">
          <h4>{judge.name} ({judge.country})</h4>
          <p>{judge.description}</p>
        </div>
      </div>
    ));
  };

  return (
    <>
        <div className="judges-container">
      <div className="overlay">
        <div className="text-content">
          <h3>Жюри</h3>
          <h1>Международной Премии Лидеров бизнеса</h1>
        </div>
      </div>
    </div>

    <div className="strategy-section ">
      <div className="strategy-card border-r">
        <h3>Жюри Международной Премии Лидеров бизнеса</h3>
        <p>
        Международная Премия Лидеров бизнеса заслуженно признана одним из самых престижных конкурсов для предпринимателей и бизнес-экспертов, и ключевым элементом её успеха является авторитетное жюри. В состав жюри приглашаются только самые заслуженные и опытные представители деловой среды, которые на протяжении многих лет своей профессиональной деятельности продемонстрировали высочайший уровень компетентности и вклад в развитие бизнеса.
        </p>
      </div>
      <div className="strategy-card">
        <h3>Тщательный отбор и престиж участия в жюри</h3>
        <p>
        Быть членом жюри Международной премии Лидеров бизнеса — это не только большая честь, но и большая ответственность. Жюри формируется на основе строгих критериев, чтобы обеспечить объективную и всестороннюю оценку участников конкурса. Каждый член жюри — это эксперт в своей области, имеет высокую репутацию в своей стране и за её пределами.
Участие в жюри не только подчеркивает профессиональный статус эксперта, но и позволяет влиять на развитие глобальных бизнес-практик, оценивая достижения самых выдающихся предпринимателей. Члены жюри также получают возможность наладить международные деловые контакты и обменяться опытом с коллегами из разных стран.

        </p>
      </div>
    </div>

    <div className="ey-business-academy-section">
      <div className="ey-text-content">
        <h2>Роль жюри</h2>
        <p>Члены жюри играют ключевую роль в определении победителей премии. Каждый эксперт не только оценивает участников по четким критериям, но и принимает активное участие в обсуждении номинантов, обмене мнениями с коллегами и совместном принятии решений. Процесс оценки проходит в несколько этапов, включая анализ достижений, обсуждение и голосование.</p>
        <p>Мы гордимся тем, что жюри Международной премии Лидеров бизнеса состоит из лучших представителей деловой среды, чей опыт и авторитет помогают премии сохранять высокий уровень доверия и объективности.</p>
        <button className="ey-learn-more-btn">Узнайте больше</button>
      </div>
      <div className="ey-image-content">
        <img src={trainingImage} alt="Академия бизнеса EY" />
      </div>
    </div>

    <div className="ey-business-academy-section">
      <div className="ey-text-content">
        <h2>Критерии отбора членов жюри</h2>
        <p>Для того чтобы стать членом жюри Международной премии Лидеров бизнеса, кандидат должен соответствовать следующим критериям:</p>
        <p><b>Заслуги и достижения в профессиональной деятельности:</b></p>
        <ul className='ey-list'>
            <li>Кандидат должен иметь значительные достижения в своей профессиональной карьере, включая внедрение инноваций, успешное руководство компаниями, выход на международные рынки или признание за вклад в развитие отрасли. Приветствуется наличие наград и премий, полученных за профессиональные успехи.</li>
        </ul>
        <p><b>Международное признание и экспертиза:</b></p>
        <ul className='ey-list'>
            <li>Особое внимание уделяется экспертам, чья деятельность получила международное признание, а их опыт работы включает проекты на глобальном уровне. Это важно, так как премия является международной и требует объективной оценки участников со всего мира.</li>
        </ul>
        <p><b>Репутация и влияние в деловой среде:</b></p>
        <ul className='ey-list'>
            <li>Члены жюри должны обладать безупречной репутацией в своей профессиональной среде, быть лидерами мнений и иметь значительное влияние на развитие отрасли. Их решения и мнение должны быть авторитетными для всех участников конкурса.</li>
        </ul>
        <p><b>Активное участие в развитии бизнеса и общества:</b></p>
        <ul className='ey-list'>
            <li>Приветствуются кандидаты, которые активно участвуют в жизни делового сообщества, поддерживают образовательные или благотворительные инициативы, а также вносят вклад в развитие предпринимательства и социальной ответственности.</li>
        </ul>
        <p><b>Объективность и профессиональная этика:</b></p>
        <ul className='ey-list'>
            <li>Члены жюри обязаны демонстрировать объективность, беспристрастность и высокие стандарты профессиональной этики при оценке участников. Они должны руководствоваться только фактами и результатами работы участников конкурса, исключая любые конфликты интересов.</li>
        </ul>
      </div>
    </div>

    <div className="judges-section">
        <h2>Судьи премии</h2> 
      <div className="year-tabs">
        {Object.keys(judgesData).map((year) => (
          <button 
            key={year}
            className={`year-tab ${activeYear === parseInt(year) ? 'active' : ''}`}
            onClick={() => setActiveYear(parseInt(year))}
          >
            {year}
          </button>
        ))}
      </div>
      <div className="judges-row">
        {renderJudges(activeYear)}
      </div>
    </div>
    </>
  );
};

export default Judges;
