import React, { useEffect } from 'react';

const WinnersPage = () => {

  useEffect(() => {
    const replaceName = () => {

      const elements = document.querySelectorAll('[data-translate-custom="true"]');
      elements.forEach((el) => {
        if (document.documentElement.lang !== 'ru') {
          el.textContent = 'Yuliya Baibabina';
        }
      });
    };

    const observer = new MutationObserver(() => {
      replaceName();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);


  return (
    <>
            <div className="winners-container">
      <div className="overlay">
        <div className="text-content">
          <h3>Победители</h3>
          <h1>Международной премии Лидеров бизнеса с 2019 по 2024 год</h1>
        </div>
      </div>
    </div>

    <div className="winners-section">
      <div className="winners-wrapper">

        <div className="winners-year">
          <h2>2019:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong> Олег Иванов (Россия) – <br /> За успешное создание сети магазинов здорового питания, которая за короткий срок охватила все крупные города России, обеспечив значительный рост доходов и клиентской базы.</li>
            <li><strong>Инновационный лидер:</strong> Степан Поляков (Украина) – За разработку уникальной технологии интеллектуальных складов, которая трансформировала логистическую отрасль, снизив издержки на хранение и транспортировку товаров на 25%.</li>
            <li><strong>Лидер устойчивого развития:</strong> Анна Ковальская (Россия) – За внедрение экологически чистых производственных процессов на текстильной фабрике, что позволило сократить выбросы углерода на 30% и уменьшить использование воды в производстве.</li>
            <li><strong>Лидер международного бизнеса:</strong> Ляйсан Утяшева (Казахстан) – За успешное расширение сети ресторанов в странах СНГ, обеспечив рост выручки на 40% и создание новых рабочих мест.</li>
            <li><strong>Прорыв года:</strong> Мария Ручная (Украина) – За запуск мобильного приложения для обучения языкам, которое за первый год собрало более 10 миллионов пользователей по всему миру.</li>
          </ul>
        </div>

        <div className="winners-year">
          <h2>2020:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong> Дмитрий Соколов (Россия) – За создание крупнейшей сети частных клиник в России и странах СНГ, обеспечив рост доходов и доступ к высококачественным медицинским услугам для миллионов людей.</li>
            <li><strong>Инновационный лидер:</strong> Максим Осипенко (Украина) – За разработку искусственного интеллекта для диагностики заболеваний на ранних стадиях, который улучшил точность диагностики на 20% и сократил время ожидания результатов.</li>
            <li><strong>Лидер устойчивого развития:</strong> Антон Шевченко (Россия) – За внедрение инновационных методов переработки пластиковых отходов в строительные материалы, которые сократили объемы свалок в стране на 15%.</li>
            <li><strong>Лидер международного бизнеса:</strong> Михаил Степанов (Казахстан) – За успешное расширение бизнеса по производству сельскохозяйственного оборудования в страны Восточной Европы и Азии.</li>
            <li><strong>Прорыв года:</strong> Алена Степанова (Украина) – За создание стартапа в сфере e-commerce, который за первый год увеличил свою оценку до 50 миллионов гривен и завоевал значительную долю рынка.</li>
          </ul>
        </div>

        <div className="winners-year">
          <h2>2021:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong> Екатерина Новикова (Россия) – За открытие сети образовательных центров в России и за рубежом, которые предложили инновационные программы обучения и привлекли десятки тысяч учеников.</li>
            <li><strong>Инновационный лидер:</strong> Султан Ахматов (Казахстан) – За создание автономной системы управления для электромобилей, которая снизила потребление энергии на 15%.</li>
            <li><strong>Лидер устойчивого развития:</strong> Иван Соколов (Россия) – За внедрение солнечных панелей на всех производственных площадках компании, что позволило сократить использование ископаемого топлива на 70%.</li>
            <li><strong>Лидер международного бизнеса:</strong> Влад Белоусов (Беларусь) – За успешный вывод бренда модной одежды на рынки Азии и Европы, увеличив международные продажи в два раза.</li>
            <li><strong>Прорыв года:</strong> Мария Наки (Молдова) – За создание стартапа в области виртуальной реальности, который привлек инвестиции в размере $100 миллионов в течение первого года существования.</li>
          </ul>
        </div>

        <div className="winners-year">
          <h2>2022:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong>                  
              <span data-translate-custom={ "Юлия Байбабина" ? "true" : "false"}> Юлия Байбабина</span>  (Казахстан) – За успешное развитие сети салонов постельного белья, став лидером на рынке Казахстана и за его пределами.</li>
            <li><strong>Инновационный лидер:</strong> Людмила Остафьева (Беларусь) – За разработку технологии умных домов, которая позволила снизить энергопотребление и повысить безопасность жилья.</li>
            <li><strong>Лидер устойчивого развития:</strong> Султан Керимов (Казахстан) – За создание программы по восстановлению лесов и снижение уровня выбросов углерода через участие крупных корпораций.</li>
            <li><strong>Лидер международного бизнеса:</strong> Андрей Незлобный (Россия) – За успешное расширение бизнеса по производству модной обуви на рынок СНГ.</li>
            <li><strong>Прорыв года:</strong> Иван Попов (Россия) – За запуск высокотехнологичной платформы для онлайн-торговли, которая достигла оборота в 500 миллионов рублей за первый год.</li>
          </ul>
        </div>

        <div className="winners-year">
          <h2>2023:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong> Юлия Петрова (Россия) – За развитие сети ресторанов с уникальной концепцией здорового питания, которая быстро завоевала популярность и вышла на международные рынки.</li>
            <li><strong>Инновационный лидер:</strong> Айсан Каримова (Казахстан) – За внедрение технологии переработки морских отходов в материалы для строительства, что изменило подход к управлению отходами в прибрежных городах.</li>
            <li><strong>Лидер устойчивого развития:</strong> Наталья Серебрякова (Россия) – За внедрение программы по минимизации отходов и использование возобновляемых источников энергии на всех предприятиях сети продуктовых магазинов.</li>
            <li><strong>Лидер международного бизнеса:</strong> Геннадий Пономаренко (Беларусь) – За успешное расширение сети технологических центров в Европе и Америке, что увеличило международные доходы компании на 60%.</li>
            <li><strong>Прорыв года:</strong> Мария Потапова (Россия) – За запуск стартапа в сфере агротехнологий, который привлек $20 миллионов инвестиций и стал ведущим игроком на рынке в СНГ.</li>
          </ul>
        </div>

        <div className="winners-year">
          <h2>2024:</h2>
          <ul>
            <li><strong>Предприниматель года:</strong> Александр Зубков (Россия) – За создание сети экотуристических баз, которые стали популярны благодаря инновационному подходу к управлению и использованию экологически чистых технологий.</li>
            <li><strong>Инновационный лидер:</strong> Камиля Исмаилова (Азербайджан) – За внедрение новой технологии хранения и транспортировки солнечной энергии, что значительно повысило эффективность использования возобновляемых источников энергии в стране.</li>
            <li><strong>Лидер устойчивого развития:</strong> Ирина Коваленко (Украина) – За разработку и реализацию инициативы по сокращению пластиковых отходов и внедрению экологически чистых упаковочных материалов в крупной сети супермаркетов.</li>
            <li><strong>Лидер международного бизнеса:</strong> Артур Михайлов (Казахстан) – За успешное расширение компании по производству беспилотных летательных аппаратов на рынки Европы и Азии, что привело к значительному увеличению экспортных доходов.</li>
            <li><strong>Прорыв года:</strong> Зарина Рахманова (Узбекистан) – За запуск стартапа в области финтех, который привлек $15 миллионов инвестиций и стал ведущей платформой для цифровых платежей в Центральной Азии.</li>
          </ul>
        </div>
      </div>
    </div>
    </>
  );
};

export default WinnersPage;
