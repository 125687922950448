import React, { useState } from 'react';

const BusinessAwardInfo = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpanded_2, setIsExpanded_2] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleExpand_2 = () => {
    setIsExpanded_2(!isExpanded_2);
  };

  return (
    <>
    <div className="award-info-section">
      <h2>Положение</h2>
      <div className={`collapsed-gradient ${isExpanded ? 'full-text' : ''}`}>
        <p>
          Международная Премия Лидеров бизнеса — это ежегодная Премия, проводимая с 2019 года, целью которой является признание и поощрение самых успешных и выдающихся предпринимателей, бизнес-экспертов и лидеров стран СНГ. Премия направлена на выявление лидеров, которые продемонстрировали исключительные результаты в развитии собственного бизнеса, внедрении инноваций, а также содействии устойчивому развитию и международному бизнесу.
        </p>
        <p>Организатором премии является «Федеральная корпорация по развитию малого и среднего предпринимательства», которая активно способствует развитию бизнес-сообщества.</p>
            <h3>Контактные данные организатора:</h3>
            <ul className='award-info-list'>
              <li>Почтовый адрес: 109074, г. Москва, Славянская площадь, д.4, стр.1 <br /></li>
              <li>Телефон: 8 800 100 11 11 <br /></li>
              <li>Электронная почта: info_@corpmsp.ru <br /></li>
              <li>Сайт: https://corpmsp.ru/</li>
            </ul>
            <p>Премия проводится для выдающихся предпринимателей со стран СНГ (Азербайджан, Армения, Беларусь, Казахстан, Кыргызстан, Молдова, Россия, Таджикистан, Туркменистан, Узбекистан, Украина).</p>
        {isExpanded && (
          <div className="expanded-text">
            <h3>2. Даты ежегодного проведения конкурса:</h3>
            <ul className='award-info-list'>
            <li>              Дата начала приема заявок: 1 апреля </li>
            <li>              Дата завершения приема заявок: 15 мая </li>
            <li>              Дата церемонии награждения: 1 июня</li>
          </ul>
            <h3>3. Номинации и критерии отбора</h3>
            <p>
              Конкурс включает пять номинаций, каждая из которых имеет свои критерии для оценки участников:
            </p>
            <h3>3.1. Предприниматель года</h3>
            <p>Описание номинации: Престижная награда для бизнесменов, которые показали выдающиеся результаты в развитии собственного бизнеса, его масштабировании и успешном продвижении на рынке.</p>
            <p>Критерии отбора:</p>
            <ul className='award-info-list'>
              <li>Устойчивый рост компании за последние три года (рост доходов, прибыли, рыночной доли).</li>
              <li>Масштабирование бизнеса, расширение компании на новые рынки.</li>
              <li>Лидерство и эффективность управления командой.</li>
              <li>Влияние бизнеса на региональную или национальную экономику.</li>
              <li>Участие компании в социальных инициативах.</li>
            </ul>
            <h3>3.2. Инновационный лидер</h3>
            <p>Описание номинации: Награда для компаний и лидеров, внедривших уникальные технологии, которые изменили отрасль и создали новые возможности.</p>
            <p>Критерии отбора:</p>
            <ul className='award-info-list'>
              <li>Уникальность и новизна внедренных технологий.</li>
              <li>Влияние инноваций на отрасль и бизнес-процессы.</li>
              <li>Широта применения технологии (количество пользователей, международный уровень).</li>
              <li>Экономическая эффективность внедрения инноваций (оптимизация расходов, рост прибыли).</li>
            </ul>
            <h3>3.3. Лидер устойчивого развития</h3>
            <p>Описание номинации: Награда за вклад в развитие бизнеса с акцентом на экологическую и социальную ответственность.</p>
            <p>Критерии отбора:</p>
            <ul className='award-info-list'>
              <li>Внедрение экологически чистых технологий и процессов.</li>
              <li>Социальная ответственность бизнеса (программы для сотрудников, поддержка местных сообществ).</li>
              <li>Долгосрочные результаты в сфере устойчивого развития.</li>
              <li>Сохранение конкурентоспособности компании на фоне инициатив устойчивого развития.</li>
            </ul>
            <h3>3.4. Лидер международного бизнеса</h3>
            <p>Описание номинации: Признание за успешное расширение бизнеса за пределы своей страны, демонстрирующее высокий уровень конкурентоспособности на мировом рынке.</p>
            <p>Критерии отбора:</p>
            <ul className='award-info-list'>
              <li>Успешный выход на международные рынки (количество стран, международные продажи).</li>
              <li>Конкурентоспособность на мировом рынке (качество продуктов и услуг, адаптация под требования различных стран).</li>
              <li>Рост международных доходов и рыночной доли.</li>
              <li>Участие в международных социальных инициативах.</li>
            </ul>
            <h3>3.5. Прорыв года</h3>
            <p>Описание номинации: Номинация для стартапов и компаний, которые показали стремительный рост или успешный старт в течение короткого периода.</p>
            <p>Критерии отбора:</p>
            <ul className='award-info-list'>
              <li>Финансовые результаты за первый год работы или за короткий срок.</li>
              <li>Уникальность и инновационность бизнес-модели.</li>
              <li>Успешное привлечение инвестиций.</li>
              <li>Потенциал для дальнейшего роста и масштабирования бизнеса.</li>
            </ul>
            <h3>4. Условия участия</h3>
            <p>
            Для участия в конкурсе, заявитель должен подать заявку через официальный сайт премии в период с 1 апреля по 15 мая (ежегодно). Все заявки будут рассмотрены жюри, и финалисты в каждой номинации будут приглашены на торжественную церемонию награждения, которая проходит ежегодно 1 июня.
            </p>
            <h3>5. Состав жюри</h3>
            <p>
            Жюри Международной премии Лидеров бизнеса состоит из признанных экспертов в области бизнеса, имеющих значительные достижения и опыт работы на международном уровне. Быть членом жюри — это высокая честь, которая доступна только наиболее заслуженным специалистам с безупречной репутацией. Каждый год состав жюри формируется с особой тщательностью.
            </p>
            <h3>Критерии отбора для членов жюри:</h3>
            <ul className='award-info-list'>
              <li>Значительные профессиональные достижения (внедрение инноваций, успешное руководство компаниями, международное признание).</li>
              <li>Высокая репутация и авторитет в деловой среде.</li>
              <li>Опыт работы на международном уровне или участие в международных проектах.</li>
              <li>Безупречная профессиональная этика и объективность в оценке участников.</li>
            </ul>
            <h3>6. Права и обязанности членов жюри</h3>
            <ul className='award-info-list'>
              <li>Члены жюри обязаны соблюдать объективность и беспристрастность при оценке участников.</li>
              <li>Каждый член жюри оценивает номинантов по установленным критериям и принимает участие в обсуждении результатов с коллегами.</li>
              <li>Решения жюри принимаются на основе анализа заявок и соответствия участников критериям отбора.</li>
            </ul>
            <h3>7. Подведение итогов и награждение</h3>
            <p>Финалисты в каждой номинации объявляются за две недели до церемонии награждения, которая проводится 1 июня. Победители получат награды и признание на международной сцене, что даст им уникальные возможности для укрепления деловых контактов и повышения репутации на мировом рынке.</p>
          </div>
        )}
      </div>

      <button onClick={toggleExpand} className="toggle-button">
        {isExpanded ? 'Свернуть' : 'Читать больше'}
      </button>
    </div>

    <div className="criteria-info-section">
      <h2>Критерии оценки</h2>
      <div className={`criteria-collapsed-gradient ${isExpanded_2 ? 'criteria-full-text' : ''}`}>
        <h3>Предприниматель года</h3>
        <p>Основные критерии:</p>

            <h3>1. Устойчивый рост компании:</h3>
            <ul className='criteria-info-list'>
                <li>Оценка финансовых показателей компании за последние три года, включая рост доходов, прибыли и рыночной доли.</li>
                <li>Наличие долгосрочной стратегии развития и стабильных результатов на фоне внешнеэкономических условий.</li>
            </ul>
        {isExpanded_2 && (
          <div className="expanded-text">
        <h3>2. Масштабирование бизнеса:</h3>
        <ul className='criteria-info-list'>
            <li>Успешное расширение компании: открытие новых филиалов, выход на международные рынки, увеличение клиентской базы.</li>
            <li>Эффективность внедрения новых бизнес-моделей или подходов для повышения производительности и роста компании.</li>
        </ul>
        <h3>3. Лидерство и управление:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка управленческих качеств предпринимателя, его способности эффективно управлять командой, вдохновлять и мотивировать сотрудников.</li>
            <li>Умение предвидеть изменения в отрасли и адаптировать бизнес к новым условиям.</li>
        </ul>
        <h3>4. Социальная и экономическая значимость:</h3>
        <ul className='criteria-info-list'>
            <li>Влияние бизнеса на региональную или национальную экономику.</li>
            <li>Участие компании в социальных инициативах, программах корпоративной социальной ответственности.</li>
        </ul>
            <h3>Инновационный лидер</h3>
            <p>Основные критерии:</p>
            <h3>1. Уникальность внедренных технологий:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка уровня новизны и оригинальности внедренных технологий или решений.</li>
            <li>Наличие патентов или других форм интеллектуальной собственности, связанных с инновациями.</li>
        </ul>
        <h3>2. Влияние на отрасль:</h3>
        <ul className='criteria-info-list'>
            <li>Измерение степени воздействия инноваций на бизнес-процессы компании и отрасль в целом.</li>
            <li>Насколько разработка изменила подходы к работе в определенной отрасли или создала новые рыночные возможности.</li>
        </ul>
        <h3>3. Масштаб внедрения инноваций:</h3>
        <ul className='criteria-info-list'>
            <li>Насколько широко внедрены инновации: количество пользователей или клиентов, использующих новое решение.</li>
            <li>Применение инноваций на международном уровне или в разных сегментах рынка.</li>
        </ul>
        <h3>4. Экономическая эффективность:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка влияния инноваций на прибыльность компании, рост выручки и оптимизацию расходов.</li>
            <li>Насколько внедренное решение позволило улучшить операционную эффективность или сократить издержки.</li>
        </ul>
        <h3>Лидер устойчивого развития</h3>
            <p>Основные критерии:</p>
            <h3>1. Экологическая ответственность:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка внедрения экологически чистых технологий и процессов производства.</li>
            <li>Использование возобновляемых источников энергии, сокращение выбросов углерода, минимизация отходов.</li>
        </ul>
        <h3>2. Социальная ответственность:</h3>
        <ul className='criteria-info-list'>
            <li>Реализация программ, направленных на улучшение условий труда, поддержку сотрудников и развитие местных сообществ.   </li>
            <li>Участие компании в инициативах по поддержке гендерного равенства, прав человека и других социальных вопросов.   </li>
        </ul>
        <h3>3. Долгосрочные экологические и социальные результаты:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка результатов, достигнутых в сфере устойчивого развития за последние годы, и их долгосрочное воздействие на общество и природу.</li>
            <li>Разработка и внедрение стратегий, направленных на повышение экологической и социальной ответственности бизнеса.</li>
        </ul>
        <h3>4. Экономическая устойчивость:</h3>
        <ul className='criteria-info-list'>
            <li>Сохранение конкурентоспособности бизнеса на фоне реализации инициатив по устойчивому развитию.</li>
            <li>Влияние этих инициатив на прибыльность и репутацию компании.</li>
        </ul>
        <h3>Лидер международного бизнеса</h3>
            <p>Основные критерии:</p>
            <h3>1. Масштаб международного присутствия:</h3>
        <ul className='criteria-info-list'>
            <li>Успешный выход компании на международные рынки: количество стран, в которых работает бизнес, и доля международных продаж в общем обороте.</li>
            <li>Эффективность использования международных партнерств и альянсов для расширения бизнеса.</li>
        </ul>
        <h3>2. Международная конкурентоспособность:</h3>
        <ul className='criteria-info-list'>
            <li>Уровень конкурентоспособности бизнеса на мировом рынке: адаптация продукции или услуг под требования разных стран и регионов.</li>
            <li>Способность конкурировать с международными лидерами отрасли по качеству, инновациям и клиентскому сервису.</li>
        </ul>
        <h3>3. Финансовые и экономические результаты:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка роста международных продаж, доходов и прибыли, достигнутых за последние три года.</li>
            <li>Увеличение рыночной доли компании за пределами родной страны.</li>
        </ul>
        <h3>4. Социальная ответственность на международной арене:</h3>
        <ul className='criteria-info-list'>
            <li>Участие компании в международных инициативах, направленных на решение глобальных проблем, таких как изменение климата, гендерное равенство или социальная справедливость.</li>
        </ul>
        <h3>Прорыв года</h3>
            <p>Основные критерии:</p>
            <h3>1. Стремительный рост компании:</h3>
        <ul className='criteria-info-list'>
            <li>Оценка финансовых показателей за короткий срок, включая рост выручки, прибыли и числа клиентов.</li>
            <li>Успешное позиционирование компании на рынке за первый год работы или за короткий период после запуска.</li>
        </ul>
        <h3>2. Уникальность бизнес-модели:</h3>
        <ul className='criteria-info-list'>
        <li>Оценка инновационности и уникальности подхода компании к ведению бизнеса.</li>
        <li>Насколько стартап или компания сумели занять уникальную нишу на рынке или предложить решение, которого ранее не существовало.</li>
        </ul>
        <h3>3. Привлечение инвестиций:</h3>
        <ul className='criteria-info-list'>
            <li>Успешные раунды привлечения инвестиций, демонстрирующие доверие к бизнесу со стороны инвесторов.</li>
            <li>Оценка объема привлеченных средств и их влияния на развитие компании.</li>
        </ul>
        <h3>4. Потенциал для дальнейшего роста:        </h3>
        <ul className='criteria-info-list'>
            <li>Насколько бизнес имеет потенциал для масштабирования и дальнейшего роста на локальном или международном уровне.</li>
            <li>Прогнозы на будущее: планы по расширению, инновациям и увеличению доли на рынке.</li>
        </ul>
        <h3>5. Влияние на рынок:</h3>
        <ul className='criteria-info-list'>
            <li>Насколько компания смогла изменить или повлиять на существующие рыночные тенденции, предложив уникальное решение или новый подход.</li>
            <li>Оценка восприятия компании на рынке: признание клиентов, медиа, конкурентов.</li>
        </ul>
        <h3>6. Продукт или услуга:</h3>
        <ul className='criteria-info-list'>
            <li>Качество и уникальность продукта или услуги, которые привлекли внимание рынка и обеспечили быстрый рост компании.</li>
            <li>Насколько продукт или услуга удовлетворяют потребности целевой аудитории и соответствуют современным стандартам качества.</li>
        </ul>
        <p>Эти критерии помогают жюри объективно оценить участников в каждой номинации, обеспечивая признание только тех бизнесменов и компаний, которые действительно внесли значительный вклад в развитие своей отрасли и демонстрируют исключительные результаты.</p>
          </div>
        )}
      </div>

      <button onClick={toggleExpand_2} className="criteria-toggle-button">
        {isExpanded_2 ? 'Свернуть' : 'Читать больше'}
      </button>
    </div>
    </>
);
};


export default BusinessAwardInfo;